import React, {useState, useEffect} from 'react';
import {MdPlusOne, MdModeEdit, MdClose, MdDeleteForever} from 'react-icons/md'
import Player from './Player';
import {db} from './firebase';
import {query, collection, writeBatch, getDocs, orderBy, onSnapshot, updateDoc, doc, addDoc, deleteDoc, Timestamp}  from 'firebase/firestore';
import gif from './img/pilates.gif'; // Tell Webpack this JS file uses this image

const style = {
  bg: `min-h-screen p-4 bg-gradient-to-t from-sky-700 to-sky-400`,
  container: `bg-slate-200 max-w-[500px] w-full m-auto rounded-lg shadow-2xl p-4`,
  heading: `text-3xl font-bold text-center text-sky-700 p-2 mb-2`,
  form: `border-b border-slate-300 flex justify-between pb-6 mb-6`,
  input: `border p-2 w-full text-xl rounded-md shadow-md`,
  buttonValid: `p-4 ml-2 bg-sky-500 text-sky-50 rounded-md`,
  buttonEdit: `p-4 ml-2 bg-yellow-500 text-yellow-50 rounded-md`,
  buttonCancel: `p-4 ml-2 bg-red-500 text-red-50 rounded-md`,
  count: `text-center text-slate-500 p-2 font-bold`,
  image: `mx-auto mb-6 rounded-lg`
}

let pollId, dbListName, dbPollName = null;

function App({admin, title, wednesday}) {
  //switch en fonction du jour mercredi ou jeudi
  style.bg = wednesday ? `min-h-screen p-4 bg-gradient-to-t from-indigo-700 to-indigo-400` : `min-h-screen p-4 bg-gradient-to-t from-sky-700 to-sky-400`;
  pollId = wednesday ? 'zQE6ydEvyyjLsZqAjQnL' : 'PeZoFV1k0Ef0Cvp5e1aQ';
  dbListName =  wednesday ? 'pilatesmercredi' : 'pilates';
  dbPollName = wednesday ? 'pollspilatesmercredi' : 'pollspilates';

  const [players, setPlayers] = useState([]);
  const [playerName, setPlayerName] = useState('');
  const [pollName, setPollName] = useState('');

  //Add player
  const addPlayer = registration => async (e) => {
    e.preventDefault(e);
    if(playerName === '') {
      alert('Merci d\'entrer votre prénom et nom !');
      return
    }
    await addDoc(collection(db, dbListName), {
      name: playerName,
      registered: registration,
      registeredAt: Timestamp.now()
    })
    setPlayerName('');
    alert('Réponse soumise !');
  }

  //Update poll name
  const updatePollName = async (e) => {
    e.preventDefault(e);
    if(pollName === '') {
      alert('Merci de donner un nom au sondage !');
      return
    }
    await updateDoc(doc(db, dbPollName, pollId), {
      name: pollName
    })
    alert('Nom du sondage modifié !');
  }

  //Read players from firebase
  useEffect(()=>{
    const q = query(collection(db, dbListName), orderBy('registeredAt'));
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      let playersArr = [];
      QuerySnapshot.forEach((doc) => {
        playersArr.push({...doc.data(), id: doc.id})
      });
      playersArr.sort((a,b) => b.registered - a.registered); //on place les inscrits en premier
      setPlayers(playersArr)
    })
    return () => unsubscribe()
  },[]);

  //Delete all players
  const deleteAllPlayers = async () => {
    if(window.confirm("Etes-vous sûre de vouloir supprimer le sondage ?")){
      const batch = writeBatch(db);
      const q = query(collection(db, dbListName));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
      //on stock la date de reset du formulaire
      await updateDoc(doc(db, dbPollName, pollId), {
        update: Timestamp.now()
      });
    }
  }

  //Read poll name from firebase
  useEffect(()=>{
    const docRef = doc(db, dbPollName, pollId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      let {name} = doc.data();
      setPollName(name);
    })
    return () => unsubscribe()
  },[]);

  //Update player registration in firebase
  const toggleRegistered = async (player) => {
    await updateDoc(doc(db, dbListName, player.id), {
      registered: !player.registered,
      registeredAt: Timestamp.now()
    })
  }

  //Delete player
  const deletePlayer = async (id) => {
    await deleteDoc(doc(db, dbListName, id))
  }

  //Title dynamique
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className={style.bg}>
      <div className={style.container}>
        <h3 className={style.heading}>{!admin ? pollName : 'Administration'}</h3>
        {admin ? null : (
        <img src={gif} alt="Pilates" className={style.image} width="468" height="200" />
        )}
        {!admin ? null : (
        <form onSubmit={updatePollName} className={style.form}>
          <input value={pollName} onChange={(e) => setPollName(e.target.value)} className={style.input} type="text" placeholder='Nom du sondage' />
          <button type="submit" className={style.buttonEdit}><MdModeEdit size={30} /></button>
          <button type="button" onClick={deleteAllPlayers} className={style.buttonCancel}><MdDeleteForever size={30} /></button>
        </form>
        )}
        <form onSubmit={addPlayer(true)} className={style.form}>
          <input value={playerName} onChange={(e) => setPlayerName(e.target.value)} className={style.input} type="text" placeholder='Prénom Nom' />
          <button type="submit" className={style.buttonValid}><MdPlusOne size={30} /></button>
          <button onClick={addPlayer(false)} className={style.buttonCancel}><MdClose size={30} /></button>
        </form>
        <ul>
          {players.map((player, index)=>(
            <Player key={index} index={index} admin={admin} player={player} toggleRegistered={toggleRegistered} deletePlayer={deletePlayer} />
          ))}
        </ul>
        <p className={style.count}>{`${players.filter((player) => { return player.registered; }).length} Inscrit(s)`}</p>
      </div>
    </div>
  );
}

export default App;
