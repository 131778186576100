// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBfv4FzGEdqGwElfEmMUS8dZ12w4TzGauM",
  authDomain: "foot-five-e7724.firebaseapp.com",
  projectId: "foot-five-e7724",
  storageBucket: "foot-five-e7724.appspot.com",
  messagingSenderId: "538067818376",
  appId: "1:538067818376:web:02973b51e03760bed34c3d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);