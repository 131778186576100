import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={< App admin={false} wednesday={false} title="Inscription Pilates Jeudi" />}></Route>
        <Route exact path='/mercredi' element={< App admin={false} wednesday={true} title="Inscription Pilates Mercredi" />}></Route>
        <Route exact path='/marlene-jeudi' element={< App admin={true} wednesday={false} title="Administration Pilates Jeudi" />}></Route>
        <Route exact path='/marlene-mercredi' element={< App admin={true} wednesday={true} title="Administration Pilates Mercredi" />}></Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

